<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            type="year"
            default="today"
            label="기준년도"
            name="trustYear"
            v-model="searchParam.trustYear"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="폐수 전량위탁 처리량/재활용 판매량"
      tableId="processManual"
      :columns="grid.columns"
      gridHeightAuto
      :data="grid.data"
      :merge="grid.merge"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :expandAll="true"
      :hideBottom="true"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList"/>
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      ref="table"
      title="월별 생산량"
      tableId="processManual"
      :columns="grid2.columns"
      gridHeightAuto
      :data="grid2.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :expandAll="true"
      :hideBottom="true"
    >
    </c-table>
    <c-table
      ref="table"
      title="폐수 원단위 분석"
      tableId="processManual"
      :columns="grid3.columns"
      gridHeightAuto
      :data="grid3.data"
      :merge="grid3.merge"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :expandAll="true"
      :hideBottom="true"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'water-uc-manage',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'plantName' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'tvTypeName',
            field: 'tvTypeName',
            label: '구분',
            style: 'width: 150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'tvUnit',
            field: 'tvUnit',
            label: '단위',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'tv01',
            field: 'tv01',
            label: '1월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv02',
            field: 'tv02',
            label: '2월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv03',
            field: 'tv03',
            label: '3월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv04',
            field: 'tv04',
            label: '4월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv05',
            field: 'tv05',
            label: '5월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv06',
            field: 'tv06',
            label: '6월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv07',
            field: 'tv07',
            label: '7월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv08',
            field: 'tv08',
            label: '8월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv09',
            field: 'tv09',
            label: '9월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv10',
            field: 'tv10',
            label: '10월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv11',
            field: 'tv11',
            label: '11월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv12',
            field: 'tv12',
            label: '12월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tvtotal',
            field: 'tvtotal',
            label: 'Total',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 270px',
            sortable: false,
          },
          {
            name: 'tvUnit',
            field: 'tvUnit',
            label: '단위',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'tv01',
            field: 'tv01',
            label: '1월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv02',
            field: 'tv02',
            label: '2월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv03',
            field: 'tv03',
            label: '3월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv04',
            field: 'tv04',
            label: '4월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv05',
            field: 'tv05',
            label: '5월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv06',
            field: 'tv06',
            label: '6월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv07',
            field: 'tv07',
            label: '7월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv08',
            field: 'tv08',
            label: '8월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv09',
            field: 'tv09',
            label: '9월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv10',
            field: 'tv10',
            label: '10월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv11',
            field: 'tv11',
            label: '11월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv12',
            field: 'tv12',
            label: '12월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tvtotal',
            field: 'tvtotal',
            label: 'Total',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
        ],
        data: [],
      },
      grid3: {
        merge: [
          { index: 0, colName: 'plantName' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'tvTypeName',
            field: 'tvTypeName',
            label: '구분',
            style: 'width: 150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'tvUnit',
            field: 'tvUnit',
            label: '단위',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'tv01',
            field: 'tv01',
            label: '1월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv02',
            field: 'tv02',
            label: '2월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv03',
            field: 'tv03',
            label: '3월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv04',
            field: 'tv04',
            label: '4월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv05',
            field: 'tv05',
            label: '5월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv06',
            field: 'tv06',
            label: '6월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv07',
            field: 'tv07',
            label: '7월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv08',
            field: 'tv08',
            label: '8월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv09',
            field: 'tv09',
            label: '9월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv10',
            field: 'tv10',
            label: '10월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv11',
            field: 'tv11',
            label: '11월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv12',
            field: 'tv12',
            label: '12월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tvtotal',
            field: 'tvtotal',
            label: 'Total',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        trustYear: '',
        plantCd: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.water.trust.uc.url;
      this.searchParam.trustYear = '2023' // 조회조건 제거
      this.getList();
    },
    getList() {
      let isAllSearch = false;
      if (this.searchParam.plantCd.split(',').length > 1) {
        isAllSearch = true;
      }
      if (this.searchParam.trustYear !== null) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          if (isAllSearch) {
            this.grid.data = this.$_.filter(_result.data, {tvTypeCdTop : 'A' });
            this.grid2.data = this.$_.filter(_result.data, {tvTypeCdTop : 'C' });
            this.grid3.data = this.$_.filter(_result.data, {tvTypeCdTop : 'D' });
          } else {
            this.grid.data = this.$_.filter(_result.data, {tvTypeCdTop : 'A', totalFlag: 'X' });
            this.grid2.data = this.$_.filter(_result.data, {tvTypeCdTop : 'C', totalFlag: 'X' });
            this.grid3.data = this.$_.filter(_result.data, {tvTypeCdTop : 'D', totalFlag: 'X' });
          }
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message:
          '기준년도를 선택하세요',
          type: 'warning', // success / info / warning / error
        });
      }
    },
  }
};
</script>
